import React from 'react'
import styles from './../../css/dashboard/result-item.module.css';
import { FaStar } from "react-icons/fa";
import { MdStickyNote2 } from "react-icons/md";
import BarChartWeek from './BarChartWeek';
import SmallCalendarMonth from './SmallCalendarMonth';
import { useFilterContext } from '../../pages/logged-in/PageUserDashboard';

export default function TableResultItem({ 
  isSelected,
  item, 
  showTooltip, 
  setShowTooltip 
}) {

    const stars = Array.from({ length: 5 }, (_, index) => index + 1);

    const fieldLabels = {
      "totalJumps": "Jumps Completed",
      "totalTime": "Time Completed",
      "totalCalories": "Calories burned",
      "rpm": "Jumps per minute"
    }



  return (
    <div className={`${styles['container']} ${styles[isSelected]}`}>
         <div className={styles['timeline']}>
            <div className={styles['content']}>
                <div className={styles['indicator']}></div>
          {item.note && (
              <div 
              onMouseEnter={() => setShowTooltip(item.id)}
              onMouseLeave={() => setShowTooltip(false)}
              className={styles['has-note']}>
               <MdStickyNote2 className={styles['item-note']}/>

          
                {showTooltip === item.id && (
                <div 
                className={styles['tooltip']}>
                  <div className={styles['inner-content']}>
                  <b>You left the following note</b>
                  {item.note}
                  </div>
                </div>
                )}
                
                
              </div>      
          )}
            </div>
         </div>

        <div className={styles['column-date']}>

        {item.dateObject && Object.entries(item.dateObject).map(([key, value]) => (
        <div key={key} className={styles[`date-part-${key}`]}>
        {value}
        </div>
        ))}
          
        </div>
        <div className={styles['column-main']}>

       
       
          <div className={styles['content']}>
          {isSelected === 'monthly' && item.hasOwnProperty('calendar') && (
          <SmallCalendarMonth 
          daysInMonth={item.calendar['daysInMonth']}
          startDay={item.calendar['startDay']}
          foundDates={item.calendar['dates']}
          />
          )}
       
            <div className={styles['content-inner']}>
            
            <div className={styles['datetime']}>{item.date}</div>
            <div className={styles['grid']}>
            
            {(item.hasOwnProperty('chart') && isSelected === 'weekly') && (
               <BarChartWeek isSelected={isSelected} data={item.chart}/>  
            )}  
               
               
               <div className={styles['fields']}>
                   
                   
                     
            


               {item.fields && Object.entries(item.fields).map(([key, fieldData]) => (
                  <div className={styles['field']}>
                          <div className={styles['column']}>
                          <div className={styles['value']}>{fieldData.value}</div>
                          <div className={styles['ranking']}>{fieldData.ranking}</div>
                          </div>
                          <span className={styles['label']}>{fieldLabels[key]}</span>
                  </div>
              ))}

              {item.activityCount && (
                 <div className={styles['field']}>
                  <div className={styles['column']}>
                      <div className={styles['value']}>{item.activityCount}</div>

                    </div>
                    <span className={styles['label']}>Activity Days</span>
                 </div>
               )}

                   

                  

          

               </div> 

               {(item.hasOwnProperty('chart') && isSelected === 'daily') && (
               <BarChartWeek isSelected={isSelected} data={item.chart}/>  
                )}  
              
            </div>

            </div>
         
          </div>

        <div className={styles['footer']}>
            {item.hasOwnProperty('rated') && (
            <div className={styles['rated']}>
            {stars.map((star, index) => (
                <FaStar key={index} color={star <= item.rated ? "F7C720" : "BFD0E0"}/>
            ))}
            </div>
            )}

            <div className={styles['id']}>No. {item.orderID}</div>
        </div>

        </div>

        <div className={styles['column-right']}>
            <div className={styles['checkbox']}></div>
        </div>
    </div>
  )
}
